<template>
  <div>
    <div class="container">
      <div class="top-block">
        <div class="top-block__img">
          <img
            src="@/assets/img/bg/bg-owners-1.png"
            alt=""
            loading="lazy"
            width="520"
            height="300"
          />
        </div>

        <AttentionBlock
          h1
          :title="$getTranslate(topBlock.title)"
          :text="$getTranslate(topBlock.text)"
        />
      </div>
    </div>

    <div class="color-blocks">
      <ColorBlock :item="colorBlockOwn1" />
      <ColorBlock :item="colorBlockOwn2" />
    </div>
    <HowBlock :items="HowBlockItems" />
    <OffersBlock owners />
  </div>
</template>

<script>
import OffersBlock from '@/components/OffersBlock.vue'
import HowBlock from '@/components/HowBlock.vue'

export default {
  name: 'OwnersPage',
  components: {
    OffersBlock,
    HowBlock
  },

   metaInfo() {
    return {
      title: this.$getTranslate('meta_owners_title'),
            meta: [
                { name: 'description', content:  this.$getTranslate('meta_owners_description')},
                { property: 'og:title', content: this.$getTranslate('meta_owners_title')},
                { property: 'og:site_name', content: 'Costaction'},
                {property: 'og:image', content: window.location.href + require('@/assets/img/bg/bg-owners-1.png')},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'}
            ]
        }
  },

  data() {
    return {
      topBlock: {
        h1: true,
        title: 'top_title_owners',
        text: 'top_text_owners'
      },

      colorBlockOwn1: {
        bg: 'circles',
        title: 'color_block_titile_owners_1',
        text: 'color_block_text_owners_1'
      },

      colorBlockOwn2: {
        red: true,
        bg: 'squares',
        title: 'color_block_titile_owners_2',
        text: 'color_block_text_owners_2'
      },

      HowBlockItems: [
        {
          title: 'how_block_title_4',
          text: 'how_block_text_4',
          img: 'how-4.svg'
        },
        {
          title: 'how_block_title_5',
          text: 'how_block_text_5',
          img: 'how-5.svg'
        },
        {
          title: 'how_block_title_6',
          text: 'how_block_text_6',
          img: 'how-6.svg'
        }
      ]
    }
  }
}
</script>
